<template>
  <q-input
    dense
    class="q-ml-sm"
    borderless
    v-model="filterPermission"
    :placeholder="filterText"
  >
    <q-select
      style="display: none"
      emit-value
      map-options
      option-label="description"
      option-value="uuid"
      v-model="model"
      :options="options"
      :rules="[requiredRule]"
    />
    <template v-slot:prepend>
      <q-icon name="mdi-magnify" />
    </template>
  </q-input>
  <q-separator />
  <q-scroll-area :style="`width: 100%; height: ${height}px`">
    <q-slide-transition>
      <div class="text-negative" v-if="!model.length">
        <div class="q-pa-sm">
          <q-icon name="mdi-alert-circle" /> {{ validationText }}
          väljas
        </div>
      </div>
    </q-slide-transition>
    <q-tree
      class="col-12 q-px-sm"
      :nodes="permissionNodes"
      :filter="filterPermission"
      node-key="uuid"
      default-expand-all
      tick-strategy="leaf"
      v-model:ticked="model"
    />
  </q-scroll-area>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue'

import { required as requiredRule } from '@/common/formValidationRules'

export interface MasterRoleTreeOption {
  uuid: string
  description: string
  group: string
  name: string
}

export default defineComponent({
  name: 'MasterRoleTree',

  props: {
    options: {
      type: Array as PropType<MasterRoleTreeOption[]>,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    height: Number,
    filterText: String,
    validationText: String,
  },

  setup(props, { emit }) {
    const filterPermission = ref('')

    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    })

    const permissionNodes = computed(() => {
      interface PermissionNodeChild {
        label: string
        uuid: string
      }

      interface PermissionNode {
        label: string
        uuid: string
        children: PermissionNodeChild[]
      }

      interface PermissionGroups {
        [group: string]: PermissionNode
      }

      const permissionGroups = props.options.reduce<PermissionGroups>(
        (acc, permission) => {
          const permissionGroup = permission.group

          if (!acc[permissionGroup]) {
            acc[permissionGroup] = {
              label: permissionGroup,
              uuid: permissionGroup,
              children: [
                {
                  uuid: permission.uuid,
                  label: `${permission.description} (${permission.name})`,
                },
              ],
            }
          } else {
            acc[permissionGroup].children.push({
              uuid: permission.uuid,
              label: `${permission.description} (${permission.name})`,
            })
          }
          return acc
        },
        {}
      )
      return Object.values(permissionGroups)
    })

    return { permissionNodes, filterPermission, model, requiredRule }
  },
})
</script>
