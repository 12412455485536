import { Ref, ref, ComputedRef, computed } from 'vue'
import { Role } from '@/types/role'
import { getRoles } from '@/api/role/getRoles'
import { deleteRole } from '@/api/role/deleteRole'
import { createRole } from '@/api/role/createRole'
import { updateRole } from '@/api/role/updateRole'
import { useLoading, Loading } from '@/composable/useLoading'

interface UseRole {
  data: Ref<Role[]>
  fetchAll: () => Promise<Role[]>
  remove: (uuid: string) => Promise<null>
  add: (body: Role) => Promise<Role>
  update: (body: Role) => Promise<Role>
  loading: ComputedRef<Loading>
}

export function useRole(initFetchAll?: boolean): UseRole {
  const data = ref<Role[]>([])
  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<Role[]>((resolve, reject) => {
      getRoles()
        .then(({ data: role }) => {
          data.value = role
          resolve(role)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteRole(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: Role) {
    state.create = true
    return new Promise<Role>((resolve, reject) => {
      createRole(body)
        .then(({ data: role }) => {
          data.value.push(role)
          resolve(role)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: Role) {
    state.update = true
    return new Promise<Role>((resolve, reject) => {
      updateRole(body)
        .then(({ data: role }) => {
          data.value = data.value.map((item) => {
            if (item.uuid === role.uuid) {
              return {
                ...item,
                ...role,
              }
            }

            return item
          })
          resolve(role)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    remove,
    add,
    update,
    loading: computed(() => state),
  }
}
