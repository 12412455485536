import { render } from "./MasterRoleTree.vue?vue&type=template&id=083564ed"
import script from "./MasterRoleTree.vue?vue&type=script&lang=ts"
export * from "./MasterRoleTree.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QSelect,QIcon,QSeparator,QScrollArea,QSlideTransition,QTree});
