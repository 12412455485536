
import { defineComponent, computed, ref, PropType } from 'vue'

import { required as requiredRule } from '@/common/formValidationRules'

export interface MasterRoleTreeOption {
  uuid: string
  description: string
  group: string
  name: string
}

export default defineComponent({
  name: 'MasterRoleTree',

  props: {
    options: {
      type: Array as PropType<MasterRoleTreeOption[]>,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    height: Number,
    filterText: String,
    validationText: String,
  },

  setup(props, { emit }) {
    const filterPermission = ref('')

    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    })

    const permissionNodes = computed(() => {
      interface PermissionNodeChild {
        label: string
        uuid: string
      }

      interface PermissionNode {
        label: string
        uuid: string
        children: PermissionNodeChild[]
      }

      interface PermissionGroups {
        [group: string]: PermissionNode
      }

      const permissionGroups = props.options.reduce<PermissionGroups>(
        (acc, permission) => {
          const permissionGroup = permission.group

          if (!acc[permissionGroup]) {
            acc[permissionGroup] = {
              label: permissionGroup,
              uuid: permissionGroup,
              children: [
                {
                  uuid: permission.uuid,
                  label: `${permission.description} (${permission.name})`,
                },
              ],
            }
          } else {
            acc[permissionGroup].children.push({
              uuid: permission.uuid,
              label: `${permission.description} (${permission.name})`,
            })
          }
          return acc
        },
        {}
      )
      return Object.values(permissionGroups)
    })

    return { permissionNodes, filterPermission, model, requiredRule }
  },
})
